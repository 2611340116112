<template>
  <slide-over-right :id="sorId" v-on:showModal="showModal" editType="standard">
    <loading :active.sync="isLoading"></loading>

    <template v-slot:header>
      <h2 class="font-semibold text-gray-900 text-22px">
        {{ getTitleText }}
      </h2>
    </template>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" class="px-6">
        <AppInput
          v-model="form.question"
          type="text"
          rules="required"
          :name="
            $t(
              'components.settingsManagement.quiz.sobriety.list.addEdit.title.question'
            )
          "
          :label="
            $t(
              'components.settingsManagement.quiz.sobriety.list.addEdit.title.question'
            )
          "
        />
        <AppInput
          v-model.number="form.answer"
          type="number"
          step="0.1"
          rules="required|between:0,10000"
          :name="
            $t(
              'components.settingsManagement.quiz.sobriety.list.addEdit.title.answer'
            )
          "
          :label="
            $t(
              'components.settingsManagement.quiz.sobriety.list.addEdit.title.answer'
            )
          "
        />
        <AppInput
          v-model.number="form.answer_countdown"
          type="number"
          step="0.1"
          rules="required|between:0,10000"
          :name="
            $t(
              'components.settingsManagement.quiz.sobriety.list.addEdit.title.countdown'
            )
          "
          :label="
            $t(
              'components.settingsManagement.quiz.sobriety.list.addEdit.title.countdown'
            )
          "
          :haveUnitText="true"
          unitText="Sec"
        />
        <UploadImageAlt
          class="max-h-14"
          :current="form.picture"
          :text="
            `Drag and drop the quiz photo and this photo must be 1:1 ratio and .jpg format`
          "
          :button-text="
            $t(
              'components.vehicleOperationPrimer.addEdit.steps.part.title.photo'
            )
          "
          :disabled="false"
          v-model="form.picture"
        />

        <div class="flex justify-end items-center mt-6">
          <slide-over-right-trigger :id="sorId">
            <anchor-button variant="secondary">{{
              $t('components.stepNavigation.cancel')
            }}</anchor-button>
          </slide-over-right-trigger>
          <t-button type="submit" class="ml-3">{{
            isEditing
              ? $t('components.stepNavigation.update')
              : $t('components.stepNavigation.save')
          }}</t-button>
        </div>
      </form>
    </ValidationObserver>
  </slide-over-right>
</template>

<script>
import xMan from '@/utils/xMan'
import { EventBus } from '@/utils/EventBus'
import { SobrietyQuizConfig } from '@/config/SobrietyQuizConfig'

export default {
  name: 'AddEditSobrietyQuiz',
  components: {
    SlideOverRight: () => import('@/components/modals/SlideOverRight'),
    SlideOverRightTrigger: () =>
      import('@/components/modals/SlideOverRightTrigger'),
    AnchorButton: () => import('@/components/form/AnchorButton.vue'),
    UploadImageAlt: () => import('@/components/form/UploadImageAlt'),
  },
  data() {
    return {
      isLoading: false,
      item: {},
      form: {},
      errors: [],
      sorId: SobrietyQuizConfig.events.sorId,
    }
  },
  computed: {
    isEditing: function() {
      return Object.keys(this.item).length !== 0
    },
    getTitleText() {
      return this.isEditing
        ? this.$t(
            'components.settingsManagement.quiz.sobriety.list.addEdit.headline.add'
          )
        : this.$t(
            'components.settingsManagement.quiz.sobriety.list.addEdit.headline.add'
          )
    },
  },
  mounted() {
    EventBus.$on(SobrietyQuizConfig.events.editingData, (item) => {
      this.item = item
      this.form = { ...this.item }
    })
  },
  methods: {
    showModal() {
      dispatchEvent(new Event(SobrietyQuizConfig.events.sorToggle))
    },
    submit: async function() {
      this.isLoading = true
      let method = this.isEditing ? 'PATCH' : 'POST'
      let url = this.isEditing
        ? SobrietyQuizConfig.api.update(this.item.id)
        : SobrietyQuizConfig.api.create

      const formDataProxy = { ...this.form }

      delete formDataProxy.id

      if (typeof formDataProxy.picture === 'string') {
        delete formDataProxy.picture
      }

      let data = new xMan(formDataProxy).toFormData()
      let message = this.isEditing
        ? 'Quiz updated successfully'
        : 'Quiz added successfully'

      try {
        let response = await this.$http({ method, url, data })
        this.isLoading = false
        // Close the slideover
        dispatchEvent(new Event(SobrietyQuizConfig.events.sorClose))
        // Refetch the indexData
        dispatchEvent(new Event(SobrietyQuizConfig.events.refresh))
        // Reset the form
        this.form = {}
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Success',
            text: message,
          },
          3000
        )
        // Resolved
        return response.status
      } catch (error) {
        // todo: remove logs
        console.log('quiz-add-edit-err', { error })
        // todo: show server errors
        this.isLoading = false
        // todo: handle server errors
        this.$notify(
          {
            group: 'generic',
            type: 'error',
            title: `Failed [${error.response.status}]`,
            text: `${
              error?.response?.data?.message
                ? error.response.data.message
                : 'Failed to take action'
            }`,
          },
          3000
        )
        // Rejected
        // throw new Error(error.status);
      }
    },
  },
}
</script>

<style></style>
