<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <div class="flex items-center justify-between">
        <div class="font-bold text-22px text-oCharcoal">
          <span>
            {{
              $t(
                'components.settingsManagement.businessHour.headline.announcement'
              )
            }}
          </span>
        </div>
      </div>
      <div
        :key="`maintenanceSettings_${counter}`"
        class=" mt-3 pt-5 border-t-2"
      >
        <template v-for="(item, itemIndex) in maintenanceSettings">
          <SmartToggleInput
            v-if="item.type === 'toggle'"
            :key="itemIndex"
            :label="item.label"
            :options="item.options"
            :placeholder="item.placeholder"
            :description="item.description"
            :onText="item.onText"
            :offText="item.offText"
            :onValue="item.onValue"
            :offValue="item.offValue"
            v-model="maintenance[`${item.variable}`]"
            @save="onSave('maintenance', `${item.variable}`, $event)"
          />
          <template v-else>
            <template v-if="!maintenance.is_service_available">
              <SmartAppInput
                v-if="item.variable === 'service_unavailability_type'"
                :key="itemIndex"
                :rowId="`maintenance_${itemIndex}`"
                :type="item.type"
                :label="item.label"
                :placeholder="item.placeholder"
                :description="item.description"
                :options="unavailabilityTypeChoices"
                :isEdit="item.isEdit"
                v-model="maintenance[`${item.variable}`]"
                @save="onSave('maintenance', `${item.variable}`, $event)"
              />
              <SmartAppInput
                v-else
                :key="itemIndex"
                :rowId="`maintenance_${itemIndex}`"
                :type="item.type"
                :label="item.label"
                :placeholder="item.placeholder"
                :description="item.description"
                :isEdit="item.isEdit"
                v-model="maintenance[`${item.variable}`]"
                @save="onSave('maintenance', `${item.variable}`, $event)"
              />
            </template>
          </template>
        </template>
      </div>

      <!-- one -->
      <div class="flex items-center justify-between mt-10">
        <div class="font-bold text-22px text-oCharcoal">
          <i class="fas fa-clock text-22px text-oTeal"></i>
          <span class="pl-3">
            {{
              $t(
                'components.settingsManagement.businessHour.headline.businessHour'
              )
            }}
          </span>
        </div>
        <div></div>
      </div>
      <!-- two -->
      <div class="hidden py-6 border-b border-oLightestGray md:flex border-t-2">
        <div class="w-1/3 font-bold text-14px text-oGray">
          {{
            $t(
              'components.settingsManagement.businessHour.businessHour.workingDay'
            )
          }}
        </div>
        <div class="w-1/3 ml-2 font-bold text-14px text-oGray">
          {{
            $t(
              'components.settingsManagement.businessHour.businessHour.startingTime'
            )
          }}
        </div>
        <div class="w-1/3 -ml-4 font-bold text-14px text-oGray">
          {{
            $t(
              'components.settingsManagement.businessHour.businessHour.endingTime'
            )
          }}
        </div>
      </div>
      <div class="block h-px mt-6 md:hidden"></div>

      <template v-for="(item, index) in schedules">
        <s-grid
          one="Working Day"
          two="Starting Time"
          three="Ending Time"
          :key="index"
          :variant="index % 2 ? 'gray' : 'white'"
        >
          <template v-slot:one>
            <div class="flex mt-3">
              <div>
                <t-toggle
                  variant="success"
                  v-model="item.is_active"
                  @change="onOff(item, $event)"
                />
              </div>
              <div class="ml-4 font-bold text-14px text-oGray">
                {{ item.day }}
              </div>
            </div>
          </template>
          <template v-slot:two>
            <div class="w-4/5">
              <ValidationObserver class="flex items-center w-full">
                <form
                  @submit.prevent
                  enctype="multipart/form-data"
                  class="w-full"
                >
                  <ValidationProvider vid="friday" rules="" v-slot="{ errors }">
                    <template
                      v-if="
                        getInputLockState(`${lowercase(item.day)}_start_time`)
                      "
                    >
                      <text-input v-model="item.start_time" :disabled="true" />
                    </template>
                    <template v-else>
                      <clock-picker
                        v-model="item.start_time"
                        placeholder="--:--"
                      />
                      <input-error-item :message="errors[0]" />
                    </template>
                  </ValidationProvider>
                </form>
                <div>
                  <anchor-button
                    @click="
                      toggleEditingState(
                        `${lowercase(item.day)}_start_time`,
                        null,
                        item
                      )
                    "
                    :text="getButtonText(`${lowercase(item.day)}_start_time`)"
                    :variant="
                      getButtonVariant(`${lowercase(item.day)}_start_time`)
                    "
                    class="ml-3"
                  />
                  <input-error-item />
                </div>
              </ValidationObserver>
            </div>
          </template>
          <template v-slot:three>
            <div class="w-4/5">
              <ValidationObserver class="flex items-center w-full">
                <form
                  @submit.prevent
                  enctype="multipart/form-data"
                  class="w-full"
                >
                  <ValidationProvider vid="friday" rules="" v-slot="{ errors }">
                    <template
                      v-if="
                        getInputLockState(`${lowercase(item.day)}_end_time`)
                      "
                    >
                      <text-input v-model="item.end_time" :disabled="true" />
                    </template>
                    <template v-else>
                      <clock-picker
                        v-model="item.end_time"
                        placeholder="--:--"
                      />
                      <input-error-item :message="errors[0]" />
                    </template>
                  </ValidationProvider>
                </form>
                <div>
                  <anchor-button
                    @click="
                      toggleEditingState(
                        `${lowercase(item.day)}_end_time`,
                        null,
                        item
                      )
                    "
                    :text="getButtonText(`${lowercase(item.day)}_end_time`)"
                    :variant="
                      getButtonVariant(`${lowercase(item.day)}_end_time`)
                    "
                    class="ml-3"
                  />
                  <input-error-item />
                </div>
              </ValidationObserver>
            </div>
          </template>
        </s-grid>
      </template>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'
import inPlaceEditingMixins from '@/mixins/inPlaceEditingMixin'
import { BusinessHourSettingsConfig } from '@/config/SettingsConfig'
import TextInput from '@/components/form/TextInput'
// import InputLabel from "@/components/form/InputLabel";
import AnchorButton from '@/components/form/AnchorButton'
import SGrid from '@/components/layout/SGrid'
import ClockPicker from '@/components/picker/clock/components/ClockPicker'
import { useEndpoints } from '@/composables'
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import SmartToggleInput from '@/components/form/SmartToggleInput.vue'
import { EventBus } from '@/utils'
export default {
  name: 'ScheduleSettings',
  mixins: [inPlaceEditingMixins],
  components: {
    TextInput,
    // InputLabel,
    AnchorButton,
    // SubmitButton,
    SGrid,
    ClockPicker,
    SmartAppInput,
    SmartToggleInput,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      schedules: [],
      inPlaceEditingState: {},
      unavailabilityTypeChoices: [],
      maintenance: {
        is_service_available: false,
        service_unavailability_type: '',
        service_unavailability_description: '',
      },
      maintenanceSettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.businessHour.announcement.allowServiceAvailable.title'
          ),
          description: this.$t(
            'components.settingsManagement.businessHour.announcement.allowServiceAvailable.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_service_available',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.businessHour.announcement.serviceUnavailabilityType.title'
          ),
          description: ``,
          variable: 'service_unavailability_type',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.businessHour.announcement.description.title'
          ),
          description: ``,
          variable: 'service_unavailability_description',
          isEdit: false,
        },
      ],
    }
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'maintenance') {
          // this.maintenanceSettings = this.maintenanceSettings.map((item) => ({
          //   ...item,
          //   isEdit: false,
          // }))
        }
      },
    },
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'maintenance') {
        this.maintenanceSettings = this.maintenanceSettings.map(
          (item, itemIndex) => {
            if (id === `maintenance_${itemIndex}`) {
              return {
                ...item,
                isEdit: true,
              }
            } else {
              return {
                ...item,
                isEdit: false,
              }
            }
          }
        )
        this.currentSettingsGroup = 'maintenance'
      }
    })
  },
  async created() {
    const businessReq = this.$http.get(
      BusinessHourSettingsConfig.api.index(this.orgId)
    )

    const maintenanceReq = this.$http.get(
      useEndpoints.organization.maintenanceSettings.details(this.orgId)
    )

    const unavailabilityTypeChoicesReq = this.$http.get(
      useEndpoints.organization.maintenanceSettings.unavailabilityTypeChoices()
    )

    const requests = [businessReq, maintenanceReq, unavailabilityTypeChoicesReq]

    await this.$http.all(requests).then(
      this.$http.spread((...responses) => {
        this.isLoaded = true

        const data = responses[0].data.data
        data.forEach((item) => {
          this.mapDataWithModel(item)
        })
        this.maintenance = responses[1].data

        this.unavailabilityTypeChoices = responses[2].data
      })
    )
  },
  methods: {
    formatTimeForClockPicker(time) {
      let parts = time.split(':')
      return parts[0] + ':' + parts[1]
    },
    lowercase(str) {
      return String(str).toLowerCase()
    },
    mapDataWithModel(item) {
      this.schedules.push({
        id: item.id,
        day: item.day,
        is_active: item.is_active,
        start_time: this.formatTimeForClockPicker(item.start_time),
        end_time: this.formatTimeForClockPicker(item.end_time),
      })

      const day = this.lowercase(item.day)

      // { friday_start_time: false }
      Vue.set(this.inPlaceEditingState, `${day}_start_time`, false)
      // Object.defineProperty(this.inPlaceEditingState, `${day}_start_time`, {
      //   value: false,
      //   writable: true
      // });

      // { friday_end_time: false }
      Vue.set(this.inPlaceEditingState, `${day}_end_time`, false)
      // Object.defineProperty(this.inPlaceEditingState, `${day}_end_time`, {
      //   value: false,
      //   writable: true
      // });
    },
    async inPlaceEditingRequest(group, key, data) {
      console.log(group, key)
      console.log(data)
      const formData = new FormData()
      formData.append('start_time', data.start_time)
      formData.append('end_time', data.end_time)

      let isStart = this.lowercase(data.day) + '_start_time'
      let isEnd = this.lowercase(data.day) + '_end_time'

      let toastTitle = ''
      let toastText = ''

      if (group === isStart) {
        toastTitle = this.$t(
          'components.settingsManagement.businessHour.toast.startingTime.title',
          { day: data.day }
        )
        toastText = this.$t(
          'components.settingsManagement.businessHour.toast.startingTime.text',
          { day: data.day, time: data.start_time }
        )
      }

      if (group === isEnd) {
        toastTitle = this.$t(
          'components.settingsManagement.businessHour.toast.endingTime.title',
          { day: data.day }
        )
        toastText = this.$t(
          'components.settingsManagement.businessHour.toast.endingTime.text',
          { day: data.day, time: data.end_time }
        )
      }

      await this.$http
        .patch(BusinessHourSettingsConfig.api.update(data.id), formData)
        .then((res) => {
          console.log(res)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          return true
        })
        .catch((err) => {
          // todo: reverse toggle state
          console.log('inPlaceE', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update business hour',
            },
            3000
          )
        })
    },
    async onOff(item, val) {
      console.log(item, val)
      const formData = new FormData()
      formData.append('is_active', val)

      await this.$http
        .patch(BusinessHourSettingsConfig.api.update(item.id), formData)
        .then((res) => {
          console.log(res)
          let onOffText =
            val === true
              ? this.$t(
                  'components.settingsManagement.businessHour.toast.day.opened'
                )
              : this.$t(
                  'components.settingsManagement.businessHour.toast.day.closed'
                )
          let onOffTextAlt =
            val === true
              ? this.$t(
                  'components.settingsManagement.businessHour.toast.day.businessDay'
                )
              : this.$t(
                  'components.settingsManagement.businessHour.toast.day.holiday'
                )

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: this.$t(
                'components.settingsManagement.businessHour.toast.day.title',
                { day: item.day, status: onOffText }
              ),
              text: this.$t(
                'components.settingsManagement.businessHour.toast.day.text',
                { day: item.day, status: onOffTextAlt }
              ),
            },
            3000
          )
          return true
        })
        .catch((err) => {
          // todo: reverse toggle state
          console.log('onOffE', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update business hour',
            },
            3000
          )
        })
    },

    async onSave(apiGroup, key, val) {
      this.counter++
      let url = ''
      if (apiGroup === 'maintenance') {
        url = useEndpoints.organization.maintenanceSettings.update(this.orgId)
      }

      let data = new FormData()
      data.append(key, val)
      await this.$http
        .patch(url, data)
        .then((res) => {
          console.log(res)
          this.maintenanceSettings = this.maintenanceSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: this.$t(
                'components.settingsManagement.businessHour.toast.success.title'
              ),
              text: this.$t(
                'components.settingsManagement.businessHour.toast.success.text'
              ),
            },
            3000
          )
          return true
        })
        .catch((err) => {
          console.log(err)
          this.$notify(
            {
              group: 'generic',
              type: 'danger',
              title: 'Failed',
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
  },
}
</script>

<style lang="scss">
.grid-size {
  height: 83px;
}
</style>
