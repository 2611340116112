<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <!-- safety test -->
      <div class="flex items-center justify-between">
        <div class="font-bold text-22px text-oCharcoal">
          <span>
            {{ $t('components.settingsManagement.quiz.headline.safety') }}
          </span>
        </div>
      </div>
      <div :key="`safetySettings_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in safetySettings">
          <SmartToggleInput
            v-if="item.type === 'toggle'"
            :key="`safetySettings_${itemIndex}`"
            :label="item.label"
            :options="item.options"
            :placeholder="item.placeholder"
            :description="item.description"
            :onText="item.onText"
            :offText="item.offText"
            :onValue="item.onValue"
            :offValue="item.offValue"
            v-model="safety[`${item.variable}`]"
            @save="onSave('safety', `${item.variable}`, $event)"
          />
        </template>
      </div>
      <!-- sobriety test -->
      <div class="flex items-center justify-between">
        <div class="font-bold text-22px text-oCharcoal">
          <span>
            {{ $t('components.settingsManagement.quiz.headline.sobriety') }}
          </span>
        </div>
      </div>
      <div :key="`sobrietySettings_${counter}`" class=" mt-3 pt-5 border-t-2">
        <template v-for="(item, itemIndex) in sobrietySettings">
          <SmartToggleInput
            v-if="item.type === 'toggle'"
            :key="`sobrietySettings_${itemIndex}`"
            :label="item.label"
            :options="item.options"
            :placeholder="item.placeholder"
            :description="item.description"
            :onText="item.onText"
            :offText="item.offText"
            :onValue="item.onValue"
            :offValue="item.offValue"
            v-model="sobriety[`${item.variable}`]"
            @save="onSave('safety', `${item.variable}`, $event)"
          />
          <template v-else>
            <template v-if="sobriety.is_sobriety_test_required">
              <SmartAppInput
                :key="itemIndex"
                :rowId="`sobrietySettings_${itemIndex}`"
                :type="item.type"
                :label="item.label"
                :placeholder="item.placeholder"
                :description="item.description"
                :options="item.options"
                :isEdit="item.isEdit"
                v-model="sobriety[`${item.variable}`]"
                :haveUnitText="item.haveUnitText"
                :unitText="item.unitText"
                :unitWidthVariant="item.unitWidthVariant"
                @save="onSave('safety', `${item.variable}`, $event)"
              />
            </template>
          </template>
        </template>
      </div>

      <template v-if="sobriety.is_sobriety_test_required">
        <div>
          <div class="flex items-center justify-between mt-5 mb-2">
            <div class="font-bold text-19px text-oCharcoal">
              <span>
                {{
                  $t(
                    'components.settingsManagement.quiz.sobriety.settings.schedule.headline'
                  )
                }}
              </span>
            </div>
            <div></div>
          </div>

          <div
            class="hidden py-6 border-b border-oLightestGray md:flex border-t-2"
          >
            <div class="w-1/3 font-bold text-14px text-oGray">
              Day
            </div>
            <div class="w-1/3 ml-2 font-bold text-14px text-oGray">
              {{
                $t(
                  'components.settingsManagement.quiz.sobriety.settings.schedule.title.startingTime'
                )
              }}
            </div>
            <div class="w-1/3 -ml-4 font-bold text-14px text-oGray">
              {{
                $t(
                  'components.settingsManagement.quiz.sobriety.settings.schedule.title.endingTime'
                )
              }}
            </div>
          </div>
          <div class="block h-px mt-6 md:hidden"></div>

          <template v-for="(item, index) in sobrietySchedules">
            <s-grid
              :one="
                $t(
                  'components.settingsManagement.quiz.sobriety.settings.schedule.title.workingDay'
                )
              "
              :two="
                $t(
                  'components.settingsManagement.quiz.sobriety.settings.schedule.title.startingTime'
                )
              "
              :three="
                $t(
                  'components.settingsManagement.quiz.sobriety.settings.schedule.title.endingTime'
                )
              "
              :key="index"
              :variant="index % 2 ? 'gray' : 'white'"
            >
              <template v-slot:one>
                <div class="flex mt-3">
                  <div>
                    <t-toggle
                      variant="success"
                      v-model="item.is_active"
                      @change="onOff(item, $event)"
                    />
                  </div>
                  <div class="ml-4 font-bold text-14px text-oGray">
                    {{ item.day }}
                  </div>
                </div>
              </template>
              <template v-slot:two>
                <div class="w-4/5">
                  <ValidationObserver class="flex items-center w-full">
                    <form
                      @submit.prevent
                      enctype="multipart/form-data"
                      class="w-full"
                    >
                      <ValidationProvider
                        vid="friday"
                        rules=""
                        v-slot="{ errors }"
                      >
                        <template
                          v-if="
                            getInputLockState(
                              `${lowercase(item.day)}_start_time`
                            )
                          "
                        >
                          <text-input
                            v-model="item.start_time"
                            :disabled="true"
                          />
                        </template>
                        <template v-else>
                          <clock-picker
                            v-model="item.start_time"
                            placeholder="--:--"
                          />
                          <input-error-item :message="errors[0]" />
                        </template>
                      </ValidationProvider>
                    </form>
                    <div>
                      <anchor-button
                        @click="
                          toggleEditingState(
                            `${lowercase(item.day)}_start_time`,
                            null,
                            item
                          )
                        "
                        :text="
                          getButtonText(`${lowercase(item.day)}_start_time`)
                        "
                        :variant="
                          getButtonVariant(`${lowercase(item.day)}_start_time`)
                        "
                        class="ml-3"
                      />
                      <input-error-item />
                    </div>
                  </ValidationObserver>
                </div>
              </template>
              <template v-slot:three>
                <div class="w-4/5">
                  <ValidationObserver class="flex items-center w-full">
                    <form
                      @submit.prevent
                      enctype="multipart/form-data"
                      class="w-full"
                    >
                      <ValidationProvider
                        vid="friday"
                        rules=""
                        v-slot="{ errors }"
                      >
                        <template
                          v-if="
                            getInputLockState(`${lowercase(item.day)}_end_time`)
                          "
                        >
                          <text-input
                            v-model="item.end_time"
                            :disabled="true"
                          />
                        </template>
                        <template v-else>
                          <clock-picker
                            v-model="item.end_time"
                            placeholder="--:--"
                          />
                          <input-error-item :message="errors[0]" />
                        </template>
                      </ValidationProvider>
                    </form>
                    <div>
                      <anchor-button
                        @click="
                          toggleEditingState(
                            `${lowercase(item.day)}_end_time`,
                            null,
                            item
                          )
                        "
                        :text="getButtonText(`${lowercase(item.day)}_end_time`)"
                        :variant="
                          getButtonVariant(`${lowercase(item.day)}_end_time`)
                        "
                        class="ml-3"
                      />
                      <input-error-item />
                    </div>
                  </ValidationObserver>
                </div>
              </template>
            </s-grid>
          </template>
        </div>
      </template>

      <div v-if="sobriety.is_sobriety_test_required" class="mt-10">
        <div class="flex items-center  justify-between">
          <div class="font-bold text-19px text-oCharcoal flex">
            <span>
              {{
                $t('components.settingsManagement.quiz.sobriety.list.headline')
              }}
            </span>
            <div
              class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
              @click="onAddSobrietyQuiz"
            >
              <i class="fas fa-plus-circle" style="color: black"></i>
            </div>
          </div>
        </div>
        <div
          :key="`sobrietySettings_${counter}`"
          class=" mt-3 pt-5 border-oLightestGray border-t-2"
        >
          <STable :sId-id="'sobriety-quiz-list'" :headers="sobrietyQuizHeaders">
            <template v-for="(quizItem, quizItemIndex) in sobrietyQuizList">
              <STableRow :key="quizItemIndex" text-fallback-always>
                <STableRowItem :text="quizItem.question" />
                <STableRowItem :text="quizItem.answer" />
                <STableRowItem :text="`${quizItem.answer_countdown} Sec`" />
                <STableRowItem>
                  <div class="flex justify-start gap-2">
                    <OtoEditIcon @click="onEditSobrietyQuiz(quizItem)" />
                    <div
                      class="cursor-pointer"
                      @click="onRemoveSobrietyQuiz(quizItem.id)"
                    >
                      <i class="fas fa-trash"></i>
                    </div>
                  </div>
                </STableRowItem> </STableRow></template
          ></STable>
        </div>
        <AddEditSobrietyQuiz />
      </div>
    </div>
  </section>
</template>
<script>
import Vue from 'vue'
import SmartToggleInput from '@/components/form/SmartToggleInput.vue'
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import inPlaceEditingMixins from '@/mixins/inPlaceEditingMixin'
import SGrid from '@/components/layout/SGrid'
import TextInput from '@/components/form/TextInput'
import AnchorButton from '@/components/form/AnchorButton'
import ClockPicker from '@/components/picker/clock/components/ClockPicker'
import AddEditSobrietyQuiz from '@/views/settings/sobriety-quiz/AddEditSobrietyQuiz.vue'
import { STable, STableRow, STableRowItem } from '@/components/s-table'
import { useEndpoints } from '@/composables'
import { SobrietyQuizConfig } from '@/config/SobrietyQuizConfig'
import { EventBus } from '@/utils'
export default {
  name: 'QuizSettings',
  mixins: [inPlaceEditingMixins],
  components: {
    SmartToggleInput,
    SmartAppInput,
    SGrid,
    TextInput,
    AnchorButton,
    ClockPicker,
    STable,
    STableRow,
    STableRowItem,
    OtoEditIcon: () => import('@/components/ui/OtoEditIcon'),
    AddEditSobrietyQuiz,
  },
  data() {
    return {
      isLoaded: false,
      counter: 0,
      currentSettingsGroup: null,
      safety: {
        is_safety_quiz_required: false,
      },
      sobriety: {
        is_sobriety_test_required: false,
        sobriety_test_max_attempts: 0,
        sobriety_test_lockdown_period: 0,
        number_of_sobriety_test_questions_to_display: 0,
      },
      sobrietySchedules: [],
      inPlaceEditingState: {},
      sobrietyQuizList: [],

      sobrietyQuizHeaders: [
        {
          text: this.$t(
            'components.settingsManagement.quiz.sobriety.list.table.question'
          ),
          width: '60%',
          sort: null,
        },
        {
          text: this.$t(
            'components.settingsManagement.quiz.sobriety.list.table.answer'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.settingsManagement.quiz.sobriety.list.table.countdown'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.settingsManagement.quiz.sobriety.list.table.action'
          ),
          width: '10%',
          sort: null,
        },
      ],

      sobrietySettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.quiz.sobriety.settings.enablePreRideSobrietyQuiz.title'
          ),
          description: this.$t(
            'components.settingsManagement.quiz.sobriety.settings.enablePreRideSobrietyQuiz.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_sobriety_test_required',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.quiz.sobriety.settings.maximumFailedQuizAttemptsPerSession.title'
          ),

          placeholder: 'e.g 3',
          description: this.$t(
            'components.settingsManagement.quiz.sobriety.settings.maximumFailedQuizAttemptsPerSession.description'
          ),
          variable: 'sobriety_test_max_attempts',
          haveUnitText: true,
          unitText: 'Times',
          unitWidthVariant: 'large',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.quiz.sobriety.settings.lockdownPeriodAfterFailedQuizAttempt.title'
          ),

          placeholder: 'e.g 3',
          description: this.$t(
            'components.settingsManagement.quiz.sobriety.settings.lockdownPeriodAfterFailedQuizAttempt.description'
          ),
          variable: 'sobriety_test_lockdown_period',
          haveUnitText: true,
          unitText: 'Minutes',
          unitWidthVariant: 'large',
          isEdit: false,
        },
        {
          type: 'number',
          label: this.$t(
            'components.settingsManagement.quiz.sobriety.settings.numberOfQuestionsToDisplay.title'
          ),

          placeholder: 'e.g 3',
          description: this.$t(
            'components.settingsManagement.quiz.sobriety.settings.numberOfQuestionsToDisplay.description'
          ),
          variable: 'number_of_sobriety_test_questions_to_display',
          haveUnitText: true,
          unitText: 'Question',
          unitWidthVariant: 'large',
          isEdit: false,
        },
      ],
      safetySettings: [
        {
          type: 'toggle',
          label: this.$t(
            'components.settingsManagement.quiz.safety.enablePreRideSafetyQuiz.title'
          ),
          description: this.$t(
            'components.settingsManagement.quiz.safety.enablePreRideSafetyQuiz.description'
          ),
          onValue: true,
          onText: this.$t('components.settingsManagement.commonAction.yes'),
          offValue: false,
          offText: this.$t('components.settingsManagement.commonAction.no'),
          variable: 'is_safety_quiz_required',
          isEdit: false,
        },
      ],
    }
  },
  async created() {
    const safetySettingsReq = this.$http.get(
      useEndpoints.settings.safety.index(this.orgId)
    )
    const sobrietySchedulesReq = this.$http.get(
      useEndpoints.settings.safety.sobrietySchedule.index()
    )
    const sobrietyQuizReq = this.$http.get(
      useEndpoints.settings.safety.sobrietyQuiz.index()
    )
    const requests = [safetySettingsReq, sobrietySchedulesReq, sobrietyQuizReq]

    await this.$http.all(requests).then(
      this.$http.spread((...responses) => {
        this.isLoaded = true
        this.safety.is_safety_quiz_required =
          responses[0].data.is_safety_quiz_required

        this.sobriety.is_sobriety_test_required =
          responses[0].data.is_sobriety_test_required
        this.sobriety.sobriety_test_max_attempts =
          responses[0].data.sobriety_test_max_attempts
        this.sobriety.sobriety_test_lockdown_period =
          responses[0].data.sobriety_test_lockdown_period
        this.sobriety.number_of_sobriety_test_questions_to_display =
          responses[0].data.number_of_sobriety_test_questions_to_display

        const sobrietyScheduleData = responses[1].data.data
        sobrietyScheduleData.forEach((item) => {
          this.mapScheduleDataWithModel(item)
        })
        this.sobrietyQuizList = responses[2].data.data
      })
    )
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'safetySettings') {
        this.safetySettings = this.safetySettings.map((item, itemIndex) => {
          if (id === `safetySettings_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'sobrietySettings'
      }

      if (idPrefix === 'sobrietySettings') {
        this.sobrietySettings = this.sobrietySettings.map((item, itemIndex) => {
          if (id === `sobrietySettings_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'sobrietySettings'
      }
    })

    window.addEventListener(SobrietyQuizConfig.events.refresh, () => {
      this.fetchSobrietyQuiz()
    })
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'sobrietySettings') {
          this.safetySettings = this.safetySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
        if (val === 'safetySettings') {
          this.sobrietySettings = this.sobrietySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
      },
    },
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
  },
  methods: {
    async onSave(apiGroup, key, val) {
      this.counter++
      let url = ''
      if (apiGroup === 'safety') {
        url = useEndpoints.settings.safety.index(this.orgId)
      }

      let data = new FormData()
      data.append(key, val)

      let toastTitle, toastText

      toastTitle = 'Success'
      toastText = 'Update Successfully'

      await this.$http
        .patch(url, data)
        .then(() => {
          this.safetySettings = this.safetySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
          this.sobrietySettings = this.sobrietySettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          return true
        })
        .catch((err) => {
          console.log(err)
          this.$notify(
            {
              group: 'generic',
              type: 'danger',
              title: 'Failed',
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
    formatTimeForClockPicker(time) {
      let parts = time.split(':')
      return parts[0] + ':' + parts[1]
    },
    lowercase(str) {
      return String(str).toLowerCase()
    },
    mapScheduleDataWithModel(item) {
      this.sobrietySchedules.push({
        id: item.id,
        day: item.day,
        is_active: item.is_active,
        start_time: this.formatTimeForClockPicker(item.start_time),
        end_time: this.formatTimeForClockPicker(item.end_time),
      })

      const day = this.lowercase(item.day)

      Vue.set(this.inPlaceEditingState, `${day}_start_time`, false)

      Vue.set(this.inPlaceEditingState, `${day}_end_time`, false)
    },
    async inPlaceEditingRequest(group, key, data) {
      const formData = new FormData()
      formData.append('start_time', data.start_time)
      formData.append('end_time', data.end_time)

      let isStart = this.lowercase(data.day) + '_start_time'
      let isEnd = this.lowercase(data.day) + '_end_time'

      let toastTitle = ''
      let toastText = ''

      if (group === isStart) {
        toastTitle = this.$t(
          'components.settingsManagement.quiz.sobriety.settings.schedule.toast.startingTime.title',
          { day: data.day }
        )
        toastText = this.$t(
          'components.settingsManagement.quiz.sobriety.settings.schedule.toast.startingTime.text',
          { day: data.day, time: data.start_time }
        )
      }

      if (group === isEnd) {
        toastTitle = this.$t(
          'components.settingsManagement.quiz.sobriety.settings.schedule.toast.endingTime.title',
          { day: data.day }
        )
        toastText = this.$t(
          'components.settingsManagement.quiz.sobriety.settings.schedule.toast.endingTime.text',
          { day: data.day, time: data.end_time }
        )
      }

      await this.$http
        .patch(
          useEndpoints.settings.safety.sobrietySchedule.update(data.id),
          formData
        )
        .then((res) => {
          console.log(res)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: toastTitle,
              text: toastText,
            },
            3000
          )
          return true
        })
        .catch((err) => {
          // todo: reverse toggle state
          console.log('inPlaceE', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update sobriety hour',
            },
            3000
          )
        })
    },
    async onOff(item, val) {
      const formData = new FormData()
      formData.append('is_active', val)

      await this.$http
        .patch(
          useEndpoints.settings.safety.sobrietySchedule.update(item.id),
          formData
        )
        .then((res) => {
          console.log(res)
          let onOffText =
            val === true
              ? this.$t(
                  'components.settingsManagement.quiz.sobriety.settings.schedule.toast.day.opened'
                )
              : this.$t(
                  'components.settingsManagement.quiz.sobriety.settings.schedule.toast.day.closed'
                )
          let onOffTextAlt =
            val === true
              ? this.$t(
                  'components.settingsManagement.quiz.sobriety.settings.schedule.toast.day.sobrietyTestDay'
                )
              : this.$t(
                  'components.settingsManagement.quiz.sobriety.settings.schedule.toast.day.testFreeDay'
                )

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: this.$t(
                'components.settingsManagement.quiz.sobriety.settings.schedule.toast.day.title',
                { day: item.day, status: onOffText }
              ),
              text: this.$t(
                'components.settingsManagement.quiz.sobriety.settings.schedule.toast.day.text',
                { day: item.day, status: onOffTextAlt }
              ),
            },
            3000
          )
          return true
        })
        .catch((err) => {
          // todo: reverse toggle state
          console.log('onOffE', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update sobriety hour',
            },
            3000
          )
        })
    },
    fetchSobrietyQuiz() {
      this.$http
        .get(useEndpoints.settings.safety.sobrietyQuiz.index())
        .then((res) => {
          this.sobrietyQuizList = res.data.data
        })
    },
    onAddSobrietyQuiz() {
      EventBus.$emit(SobrietyQuizConfig.events.editingData, {})
      dispatchEvent(new Event(SobrietyQuizConfig.events.sorToggle))
    },
    onEditSobrietyQuiz(item) {
      EventBus.$emit(SobrietyQuizConfig.events.editingData, item)
      dispatchEvent(new Event(SobrietyQuizConfig.events.sorToggle))
    },
    async onRemoveSobrietyQuiz(itemId) {
      const url = useEndpoints.settings.safety.sobrietyQuiz.delete(itemId)
      await this.$http
        .delete(url)
        .then(() => {
          this.fetchSobrietyQuiz()
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Delete Successfully',
            },
            3000
          )
        })
        .catch((err) => {
          // todo: reverse toggle state
          console.log('quiz-err', { err })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to delete',
            },
            3000
          )
        })
    },
  },
}
</script>
<style lang=""></style>
