<template>
  <section>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>

    <div class="max-w-6xl mx-auto mt-3 lg:mt-10 md:mt-5" v-if="isLoaded">
      <EmailChange
        :id="`email-change`"
        :email="profile.email"
        :sendEmailURL="getSendEmailURL"
        :verifyEmailURL="getVerifyEmailURL"
        @success="successEmailChangeAction"
        @cancel="cancelEmailChangeAction"
      />
      <!-- Group Trip -->
      <div class="w-full">
        <div class="flex items-center">
          <i class="fas fa-user text-orange-500 text-22px"></i>
          <div class="pl-3 font-bold text-22px text-oCharcoal">
            {{
              $t(
                'components.settingsManagement.profile.headline.profileSettings'
              )
            }}
          </div>
        </div>
        <div :key="`profileSettings_${counter}`" class=" mt-3 pt-5 border-t-2">
          <SmartAppInput
            :key="`profile_${counter + 0}`"
            :rowId="`profile_${0}`"
            :type="profileSettings[0].type"
            :label="profileSettings[0].label"
            :options="profileSettings[0].options"
            :placeholder="profileSettings[0].placeholder"
            :description="profileSettings[0].description"
            :isEdit="profileSettings[0].isEdit"
            v-model="profile[`${profileSettings[0].variable}`]"
            @save="onSave('org-user', `${profileSettings[0].variable}`, $event)"
          />
          <SmartAppInput
            :key="`profile_${counter + 1}`"
            :rowId="`profile_${1}`"
            :type="profileSettings[1].type"
            :label="profileSettings[1].label"
            :options="profileSettings[1].options"
            :placeholder="profileSettings[1].placeholder"
            :description="profileSettings[1].description"
            :isEdit="profileSettings[1].isEdit"
            :showEditPencil="false"
            v-model="profile[`${profileSettings[1].variable}`]"
            @save="onSave('org-user', `${profileSettings[1].variable}`, $event)"
          />

          <SmartAppInput
            :key="`profile_${counter + 2}`"
            :rowId="`profile_${2}`"
            :type="profileSettings[2].type"
            :label="profileSettings[2].label"
            :options="profileSettings[2].options"
            :placeholder="profileSettings[2].placeholder"
            :description="profileSettings[2].description"
            :isEdit="profileSettings[2].isEdit"
            v-model="profile[`${profileSettings[2].variable}`]"
            :isShowChecker="getShowCheckerStatusForPhoneNumber"
            :isCheckStart="isPhoneNumberChecking"
            :canBeSaved="getPhoneNumberSaveStatus"
            :isCancelButtonDisabled="isPhoneNumberChecking"
            :isSaveButtonDisabled="getSaveButtonStatusForPhoneNumber"
            @input="onChangePhoneNumber"
            @cancel="cancelPhoneNumberChangeAction"
            @save="onPhoneNumber"
          />
          <SmartAppInput
            :key="`profile_${counter + 3}`"
            :rowId="`profile_${3}`"
            :type="profileSettings[3].type"
            :label="profileSettings[3].label"
            :options="profileSettings[3].options"
            :placeholder="profileSettings[3].placeholder"
            :description="profileSettings[3].description"
            :isEdit="profileSettings[3].isEdit"
            :showEditPencil="false"
            v-model="profile[`${profileSettings[3].variable}`]"
            @save="onSave('org-user', `${profileSettings[3].variable}`, $event)"
          />
          <SmartAppInput
            :key="`profile_${counter + 4}`"
            :rowId="`profile_${4}`"
            :type="profileSettings[4].type"
            :label="profileSettings[4].label"
            :options="profileSettings[4].options"
            :placeholder="profileSettings[4].placeholder"
            :description="profileSettings[4].description"
            :isEdit="profileSettings[4].isEdit"
            v-model="profile[`${profileSettings[4].variable}`]"
            :isShowChecker="getShowCheckerStatusForEmail"
            :isCheckStart="isEmailChecking"
            :canBeSaved="getEmailSaveStatus"
            :isCancelButtonDisabled="isEmailChecking"
            :isSaveButtonDisabled="getSaveButtonStatusForEmail"
            @input="onChangeMail"
            @cancel="cancelEmailChangeAction"
            @save="onEmail"
          />
          <SmartAppInput
            :key="`profile_${counter + 5}`"
            :rowId="`profile_${5}`"
            :type="profileSettings[5].type"
            :label="profileSettings[5].label"
            :options="supportedLanguage"
            :placeholder="profileSettings[5].placeholder"
            :description="profileSettings[5].description"
            :isEdit="profileSettings[5].isEdit"
            v-model="profile[`${profileSettings[5].variable}`]"
            @save="onSave('org-user', `${profileSettings[5].variable}`, $event)"
          />
        </div>
      </div>
      <!-- battery -->
      <div class="flex justify-between mt-10">
        <div>
          <div class="flex items-center">
            <i class="fas fa-lock text-blue-700 text-22px"></i>
            <div class="pl-3 font-bold text-22px text-oCharcoal">
              {{
                $t(
                  'components.settingsManagement.profile.headline.accountSecurity.title'
                )
              }}
            </div>
          </div>
          <p class="break-words description-text ">
            {{
              $t(
                'components.settingsManagement.profile.headline.accountSecurity.description'
              )
            }}
          </p>
        </div>
        <div
          class="col-span-12 pencil-icon md:col-span-3 justify-items-end justify-self-end"
        >
          <div v-if="!isPasswordEdit">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              @click="handlePassEdit"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="{2}"
                d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
              />
            </svg>
          </div>
        </div>
        <div v-if="isPasswordEdit" class="flex ml-3 mt-3 mb-4">
          <anchor-button
            @click="handlePasswordCancel"
            :text="$t('components.stepNavigation.cancel')"
            width="6rem"
          />
          <anchor-button
            @click="onSavePassword"
            :isLoading="isSavePassButtonLoading"
            :disabled="getSavePasswordButtonDisableStatus"
            :text="
              $t(
                'components.settingsManagement.profile.security.action.changePassword'
              )
            "
            variant="success"
            width="8rem"
            class="ml-4"
          />
        </div>
      </div>
      <div class=" mt-3 pt-5 border-t-2">
        <div class="grid grid-cols-12">
          <div class="col-span-12  md:col-span-3">
            <div class="label ">
              <input-label
                :text="
                  $t(
                    'components.settingsManagement.profile.security.title.currentPassword'
                  )
                "
              />
            </div>
          </div>
          <div class="col-span-12  md:col-span-6 ">
            <AppInput
              v-model="passwordModel.old_password"
              rules="min:8"
              type="password"
              :name="
                $t(
                  'components.settingsManagement.profile.security.title.currentPassword'
                )
              "
              :disabled="!isPasswordEdit"
              placeholder="********"
            />
          </div>
        </div>
        <div v-if="isPasswordEdit" class="grid grid-cols-12">
          <div class="col-span-12  md:col-span-3">
            <div class="label ">
              <input-label
                :text="
                  $t(
                    'components.settingsManagement.profile.security.title.newPassword'
                  )
                "
              />
            </div>
          </div>
          <div class="col-span-12  md:col-span-6 ">
            <AppInput
              v-model="passwordModel.new_password"
              rules="min:8"
              type="password"
              :name="
                $t(
                  'components.settingsManagement.profile.security.title.repeatPassword'
                )
              "
              placeholder=""
            />
          </div>
        </div>
        <div v-if="isPasswordEdit" class="grid grid-cols-12">
          <div class="col-span-12  md:col-span-3">
            <div class="label ">
              <input-label
                :text="
                  $t(
                    'components.settingsManagement.profile.security.title.repeatPassword'
                  )
                "
              />
            </div>
          </div>
          <div class="col-span-12  md:col-span-6 ">
            <AppInput
              v-model="passwordModel.confirm_new_password"
              rules="min:8"
              type="password"
              :name="
                $t(
                  'components.settingsManagement.profile.security.title.repeatPassword'
                )
              "
              @input="onCheckPassMatch"
              placeholder=""
              :haveSuggestion="havePassMatchSuggestion"
              :isSuggestionAlert="havePassMatchSuggestion"
              :suggestionText="`Need to match with New Password`"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SmartAppInput from '@/components/form/SmartAppInput.vue'
import EmailChange from '@/components/modals/EmailChange.vue'
import AnchorButton from '@/components/form/AnchorButton'
import InputLabel from '@/components/form/InputLabel'
import { useEndpoints } from '@/composables'
import { EventBus } from '@/utils'
import debounce from 'lodash.debounce'
export default {
  name: 'ProfileSettings',
  components: {
    SmartAppInput,
    EmailChange,
    AnchorButton,
    InputLabel,
  },

  data() {
    return {
      isLoaded: false,
      isPhoneNumberExist: null,
      isPhoneNumberChecking: false,
      isEmailChecking: false,
      isEmailExist: null,
      isPasswordEdit: false,
      isPassMatched: false,
      havePassMatchSuggestion: false,
      isSavePassButtonLoading: false,
      counter: 0,
      currentSettingsGroup: null,
      profile: {
        full_name: null,
        username: null,
        email: null,
        phone_number: null,
        owner_expiry_date: null,
        language: null,
      },
      passwordModel: {
        old_password: null,
        new_password: null,
        confirm_new_password: null,
      },
      supportedLanguage: [],
      currentProfileEmail: null,
      currentProfilePhoneNumber: null,
      profileSettings: [
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.profile.profileSettings.fullName.title'
          ),
          placeholder: 'e.g John',
          description: this.$t(
            'components.settingsManagement.profile.profileSettings.fullName.description'
          ),
          variable: 'full_name',
          isEdit: false,
        },
        {
          type: 'text',
          label: this.$t(
            'components.settingsManagement.profile.profileSettings.username.title'
          ),
          placeholder: 'e.g john',
          description: this.$t(
            'components.settingsManagement.profile.profileSettings.username.description'
          ),
          variable: 'username',
          isEdit: false,
        },
        {
          type: 'tel',
          label: this.$t(
            'components.settingsManagement.profile.profileSettings.phoneNumber.title'
          ),
          placeholder: 'e.g 018',
          description: this.$t(
            'components.settingsManagement.profile.profileSettings.phoneNumber.description'
          ),
          variable: 'phone_number',
          isEdit: false,
        },
        {
          type: 'date',
          label: this.$t(
            'components.settingsManagement.profile.profileSettings.dashboardAccessExpiryDate.title'
          ),
          placeholder: 'e.g 018',
          description: this.$t(
            'components.settingsManagement.profile.profileSettings.dashboardAccessExpiryDate.description'
          ),
          variable: 'owner_expiry_date',
          isEdit: false,
        },
        {
          type: 'email',
          label: this.$t(
            'components.settingsManagement.profile.profileSettings.email.title'
          ),
          placeholder: 'e.g test@test.com',
          description: this.$t(
            'components.settingsManagement.profile.profileSettings.email.description'
          ),
          variable: 'email',
          isEdit: false,
        },
        {
          type: 'richselect',
          label: this.$t(
            'components.settingsManagement.profile.profileSettings.language.title'
          ),
          description: this.$t(
            'components.settingsManagement.profile.profileSettings.language.description'
          ),
          variable: 'language',
          isEdit: false,
        },
      ],
    }
  },
  async created() {
    await this.fetchData()
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    orgUserId() {
      return this.$store.getters['auth/userInfo'].id || null
    },
    getSendEmailURL() {
      return useEndpoints.user.org.resetUserEmail()
    },
    getVerifyEmailURL() {
      return useEndpoints.user.org.resetUserEmailVerify()
    },
    getShowCheckerStatusForPhoneNumber() {
      if (
        this.isPhoneNumberExist !== null ||
        this.isPhoneNumberChecking !== false
      ) {
        return true
      } else {
        return false
      }
    },
    getPhoneNumberSaveStatus() {
      if (this.isPhoneNumberExist === true) {
        return false
      } else if (this.isPhoneNumberExist === false) {
        return true
      } else {
        return null
      }
    },
    getSaveButtonStatusForPhoneNumber() {
      if (this.isPhoneNumberChecking === true) {
        return true
      } else if (this.isPhoneNumberExist) {
        return true
      } else {
        return false
      }
    },
    getShowCheckerStatusForEmail() {
      if (this.isEmailExist !== null || this.isEmailChecking !== false) {
        return true
      } else {
        return false
      }
    },
    getSaveButtonStatusForEmail() {
      if (this.isEmailChecking === true) {
        return true
      } else if (this.isEmailExist) {
        return true
      } else {
        return false
      }
    },
    getEmailSaveStatus() {
      if (this.isEmailExist === true) {
        return false
      } else if (this.isEmailExist === false) {
        return true
      } else {
        return null
      }
    },
    getSavePasswordButtonDisableStatus() {
      if (
        this.isPassMatched &&
        this.passwordModel.old_password &&
        this.passwordModel.new_password &&
        this.passwordModel.confirm_new_password &&
        String(this.passwordModel.old_password).length &&
        String(this.passwordModel.new_password).length >= 8 &&
        String(this.passwordModel.confirm_new_password).length >= 8
      ) {
        return false
      } else {
        return true
      }
    },
  },
  mounted() {
    EventBus.$on('open-edit', (id) => {
      const idPrefix = id.split('_')[0]
      if (idPrefix === 'profile') {
        this.profileSettings = this.profileSettings.map((item, itemIndex) => {
          if (id === `profile_${itemIndex}`) {
            return {
              ...item,
              isEdit: true,
            }
          } else {
            return {
              ...item,
              isEdit: false,
            }
          }
        })
        this.currentSettingsGroup = 'profile'
        this.isPasswordEdit = false
      }
    })
  },
  watch: {
    currentSettingsGroup: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val === 'profile') {
          this.profileSettings = this.profileSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
      },
    },
    isPasswordEdit: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.counter++
          this.profileSettings = this.profileSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))
        }
      },
    },
  },

  methods: {
    async fetchData() {
      const orgReq = this.$http.get(
        useEndpoints.organization.details(this.orgId)
      )
      const orgSupportedLanguageReq = this.$http.get(
        useEndpoints.dropdown.orgSupportedLanguage(this.orgUserId)
      )

      const requests = [orgReq, orgSupportedLanguageReq]
      await this.$http.all(requests).then(
        this.$http.spread((...responses) => {
          this.isLoaded = true
          this.profile.full_name = responses[0].data.owner_user.full_name
          this.profile.username = responses[0].data.owner_user.username

          this.profile.phone_number = responses[0].data.owner_user.phone_number
          this.currentProfilePhoneNumber =
            responses[0].data.owner_user.phone_number

          this.profile.owner_expiry_date = responses[0].data.owner_user
            .owner_expiry_date
            ? responses[0].data.owner_user.owner_expiry_date
                .split('/')
                .reverse()
                .join('-')
            : ''

          this.profile.email = responses[0].data.owner_user.email

          this.currentProfileEmail = responses[0].data.owner_user.email

          this.profile.language = responses[0].data.owner_user.profile.language

          this.supportedLanguage = responses[1].data.map((item) => {
            return {
              text: item.local_name,
              value: item.id,
            }
          })
          console.log('user-d', responses, this.supportedLanguage)
        })
      )
    },
    async onSave(apiGroup, key, val) {
      this.counter++
      let url = ''
      if (apiGroup === 'org-user') {
        url = useEndpoints.user.org.update(this.orgUserId)
      }

      let data = new FormData()
      data.append(key, val)
      await this.$http
        .patch(url, data)
        .then((res) => {
          console.log(res)
          this.profileSettings = this.profileSettings.map((item) => ({
            ...item,
            isEdit: false,
          }))

          if (key === 'phone_number') {
            this.currentProfilePhoneNumber = val
          }
          if (key === 'language') {
            console.log('language')
            this.$store
              .dispatch('auth/logout')
              .then(() => {
                this.$router.push('/login')
              })
              .catch((error) => {
                // todo: show error
                // todo: show error
                console.log(error)
              })
          }

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Update Successfully',
            },
            3000
          )
          return true
        })
        .catch((err) => {
          console.log(err)
          this.$notify(
            {
              group: 'generic',
              type: 'danger',
              title: 'Failed',
              text: 'Failed to update settings',
            },
            3000
          )
        })
    },
    getActualNumber(phoneNumberStr) {
      return phoneNumberStr.replace('-', '').replace(' ', '')
    },
    onChangePhoneNumber(e) {
      if (!this.getActualNumber(this.profile.phone_number)) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: 'Info',
            text: 'Please provide your phone number',
          },
          3000
        )
      }
      if (
        this.getActualNumber(this.profile.phone_number) &&
        this.getActualNumber(this.currentProfilePhoneNumber) !==
          this.getActualNumber(this.profile.phone_number)
      ) {
        this.isPhoneNumberChecking = true
        this.onCheckPhoneNumber(e)
      } else {
        this.isPhoneNumberExist = null
        this.isPhoneNumberChecking = false
      }
    },
    onCheckPhoneNumber: debounce(function(e) {
      this.$http
        .get(
          useEndpoints.user.org.checkPhoneNumberExistence(
            this.getActualNumber(e).replace('+', '%2b')
          )
        )
        .then((res) => {
          this.isPhoneNumberExist = res.data.phone_number_exists
        })
        .catch((err) => console.log('onCheckEmail-err', err.response))
        .finally(() => (this.isPhoneNumberChecking = false))
    }, 2000),
    onPhoneNumber() {
      if (
        this.getActualNumber(this.profile.phone_number) &&
        this.getActualNumber(this.currentProfilePhoneNumber) !==
          this.getActualNumber(this.profile.phone_number)
      ) {
        this.onSave(
          'org-user',
          'phone_number',
          this.getActualNumber(this.profile.phone_number)
        )
        this.isPhoneNumberExist = null
        this.isPhoneNumberChecking = false
      }
    },
    cancelPhoneNumberChangeAction() {
      // this.profile.phone_number = this.currentProfilePhoneNumber
      this.isPhoneNumberExist = null
    },
    onEmail() {
      if (
        this.currentProfileEmail !== this.profile.email &&
        this.isEmailExist === false
      ) {
        this.$modal.show(`email-change`)
      }
    },

    onChangeMail(e) {
      if (!e) {
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: 'Info',
            text: 'Please provide your email address',
          },
          3000
        )
      }
      if (
        this.profile.email &&
        this.currentProfileEmail !== this.profile.email
      ) {
        this.isEmailChecking = true
        this.onCheckEmail(e)
      } else {
        this.isEmailExist = null
        this.isEmailChecking = false
      }
    },
    onCheckEmail: debounce(function(e) {
      this.$http
        .get(useEndpoints.user.org.checkEmailExistence(e.replace('+', '%2b')))
        .then((res) => {
          this.isEmailExist = res.data.email_exists
        })
        .catch((err) => console.log('onCheckEmail-err', err.response))
        .finally(() => (this.isEmailChecking = false))
    }, 2000),
    cancelEmailChangeAction() {
      this.isEmailExist = null
    },
    async successEmailChangeAction() {
      this.counter++
      this.currentProfileEmail = this.profile.email
      this.isEmailExist = null
      this.profileSettings = this.profileSettings.map((item) => ({
        ...item,
        isEdit: false,
      }))
    },
    handlePassEdit() {
      this.isPasswordEdit = true
    },
    handlePasswordCancel() {
      this.isPasswordEdit = false
      this.isPassMatched = false
      this.isSavePassButtonLoading = false
      this.passwordModel.old_password = null
      this.passwordModel.new_password = null
      this.passwordModel.confirm_new_password = null
    },

    onCheckPassMatch: debounce(function(e) {
      if (
        String(this.passwordModel.new_password).length >= 8 &&
        String(this.passwordModel.confirm_new_password).length >= 8
      ) {
        if (
          this.passwordModel.new_password !==
          this.passwordModel.confirm_new_password
        ) {
          this.isPassMatched = false
          this.havePassMatchSuggestion = true
          // this.$notify(
          //   {
          //     group: 'generic',
          //     type: 'default',
          //     title: 'Info',
          //     text: 'Need to match with New Password field',
          //   },
          //   3000
          // )
        } else {
          this.isPassMatched = true
          this.havePassMatchSuggestion = false
        }
        console.log('onCheckPassMatch', e)
      }
    }, 1000),
    onSavePassword() {
      if (!this.getSavePasswordButtonDisableStatus) {
        this.isSavePassButtonLoading = true
        const url = useEndpoints.user.org.changePassword(this.orgUserId)
        const formData = new FormData()
        formData.append('old_password', this.passwordModel.old_password)
        formData.append('new_password', this.passwordModel.new_password)
        formData.append(
          'confirm_new_password',
          this.passwordModel.confirm_new_password
        )

        this.$http
          .post(url, formData)
          .then(() => {
            this.$notify(
              {
                group: 'generic',
                type: 'success',
                title: 'Success',
                text: 'Update Successfully',
              },
              3000
            )
            this.handlePasswordCancel()
          })
          .catch((err) => {
            console.log('savePassErr', err.response)
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Failed [${err.response.status}]`,
                text: `${err.response.data.message}`,
              },
              3000
            )
          })
          .finally(() => (this.isSavePassButtonLoading = false))
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.description-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 2px;
  margin-bottom: 1px;
}
</style>
