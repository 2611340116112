var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId,"editType":"standard"},on:{"showModal":_vm.showModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"font-semibold text-gray-900 text-22px"},[_vm._v(" "+_vm._s(_vm.getTitleText)+" ")])]},proxy:true}])},[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('AppInput',{attrs:{"type":"text","rules":"required","name":_vm.$t(
            'components.settingsManagement.quiz.sobriety.list.addEdit.title.question'
          ),"label":_vm.$t(
            'components.settingsManagement.quiz.sobriety.list.addEdit.title.question'
          )},model:{value:(_vm.form.question),callback:function ($$v) {_vm.$set(_vm.form, "question", $$v)},expression:"form.question"}}),_c('AppInput',{attrs:{"type":"number","step":"0.1","rules":"required|between:0,10000","name":_vm.$t(
            'components.settingsManagement.quiz.sobriety.list.addEdit.title.answer'
          ),"label":_vm.$t(
            'components.settingsManagement.quiz.sobriety.list.addEdit.title.answer'
          )},model:{value:(_vm.form.answer),callback:function ($$v) {_vm.$set(_vm.form, "answer", _vm._n($$v))},expression:"form.answer"}}),_c('AppInput',{attrs:{"type":"number","step":"0.1","rules":"required|between:0,10000","name":_vm.$t(
            'components.settingsManagement.quiz.sobriety.list.addEdit.title.countdown'
          ),"label":_vm.$t(
            'components.settingsManagement.quiz.sobriety.list.addEdit.title.countdown'
          ),"haveUnitText":true,"unitText":"Sec"},model:{value:(_vm.form.answer_countdown),callback:function ($$v) {_vm.$set(_vm.form, "answer_countdown", _vm._n($$v))},expression:"form.answer_countdown"}}),_c('UploadImageAlt',{staticClass:"max-h-14",attrs:{"current":_vm.form.picture,"text":"Drag and drop the quiz photo and this photo must be 1:1 ratio and .jpg format","button-text":_vm.$t(
            'components.vehicleOperationPrimer.addEdit.steps.part.title.photo'
          ),"disabled":false},model:{value:(_vm.form.picture),callback:function ($$v) {_vm.$set(_vm.form, "picture", $$v)},expression:"form.picture"}}),_c('div',{staticClass:"flex justify-end items-center mt-6"},[_c('slide-over-right-trigger',{attrs:{"id":_vm.sorId}},[_c('anchor-button',{attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.$t('components.stepNavigation.cancel')))])],1),_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.isEditing ? _vm.$t('components.stepNavigation.update') : _vm.$t('components.stepNavigation.save')))])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }