export const SobrietyQuizConfig = {
  api: {
    // get
    index: '/dashboard/sobriety-test-questions/',
    // post
    create: '/dashboard/sobriety-test-questions/',
    // get
    details: (id = 'uuid') => {
      return `/dashboard/sobriety-test-questions/${id}/`
    },
    // patch
    update: (id = 'uuid') => {
      return `/dashboard/sobriety-test-questions/${id}/`
    },
    // delete
    delete: (id = 'uuid') => {
      return `/dashboard/sobriety-test-questions/${id}/`
    },
  },
  events: {
    name: 'sobriety-quiz',
    // refresh-index-data
    refresh: `rid-sobriety-quiz`,
    // slideover-right
    sorId: 'sobriety-quiz',
    sorOpen: 'sor-open-sobriety-quiz',
    sorClose: 'sor-close-sobriety-quiz',
    sorToggle: 'sor-toggle-sobriety-quiz',
    // editing-data
    editingData: 'edit-sobriety-quiz-data',
    // viewing-data
    viewingData: 'view-sobriety-quiz-data',
  },
}
